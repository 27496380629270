import React, { Component } from 'react';
import styled from 'styled-components';
import MongoDBService from '../data/MongoDBService';
//import ReactGA from 'react-ga';

import Topbar from './Topbar/Topbar';
import MapUI from './MapUI';
import Sheet from './Sheet';
import StageLightbox from './StageLightbox';
// import AerostatLightbox from './AerostatLightbox';

import './world.scss';
import StageContainer from "./StageContainer";

import {ARR_GODS} from '../consts.js';

const Frame = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  overflow: hidden;
`;

const EditingNotification = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: lime;
  text-align: center;
  color: white;
  padding: 3px;
  font-weight: bold;
`;

class World extends Component {

  constructor(props) {
    super(props);

    this.handlePanClick = this.handlePanClick.bind(this);

    let { canvasRatio } = props;
    const { innerWidth, innerHeight } = window;

    let initialScale = 1;
    let canvasSize = canvasRatio;
    const multiplier = Math.pow(10, 1 || 0);

    if (innerWidth > canvasRatio.width) {

      initialScale = Math.round((innerWidth / canvasRatio.width) * multiplier) / multiplier + 0.1;
      canvasSize = Object.assign({}, {
        width: innerWidth,
        height: (innerWidth * canvasRatio.height ) / canvasRatio.width
      });

    } else if (innerWidth > canvasRatio.width) {

      initialScale = Math.round((innerHeight / canvasRatio.height) * multiplier) / multiplier + 0.1;
      canvasSize = Object.assign({}, {
        width: (innerHeight * canvasRatio.width ) / canvasRatio.height,
        height: innerHeight
      });

    }

    this.state = {
      canvasRatio,
      canvasSize,
      frameSize: {
        width: null,
        height: null
      },
      position: {
        x: 0,
        y: 0,
      },
      scale: initialScale,
      exhibitors: [],
      trees: [],
      currentExhibitor: null,
      lightboxStage : false,
    };

  }

  async componentDidMount() {

    const { canvasRatio } = this.state;
    const { numTrees } = this.props;

    const exhibitorsList = await MongoDBService.getExhibitors();

    const exhibitors = [
      ...exhibitorsList,
      // ...exhibitorsList.map(item => Object.assign({}, item, {
      //   x: item.x + 2082,
      //   y: item.y
      // })),
      // ...exhibitorsList.map(item => Object.assign({}, item, {
      //   x: item.x + 2082,
      //   y: item.y + 1600
      // })),
      // ...exhibitorsList.map(item => Object.assign({}, item, {
      //   x: item.x,
      //   y: item.y + 1600
      // }))
    ];

    let randMinX = 100;
    let randMaxX = canvasRatio.width - 100;
    let randMinY = 100;
    let randMaxY = canvasRatio.height - 100;

    const threesList = Array(numTrees).fill(null).map((item,index) => ({
      index,
      x: Math.floor(Math.random() * (randMaxX - randMinX) + randMinX),
      y: Math.floor(Math.random() * (randMaxY - randMinY) + randMinY),
    }));

    const threes = [
      ...threesList,
      // ...threesList.map(item => Object.assign({}, item, {
      //   x: item.x + 2082,
      //   y: item.y
      // })),
      // ...threesList.map(item => Object.assign({}, item, {
      //   x: item.x + 2082,
      //   y: item.y + 1600
      // })),
      // ...threesList.map(item => Object.assign({}, item, {
      //   x: item.x,
      //   y: item.y + 1600
      // }))
    ];

    this.setState({
      threes,
      exhibitors
    });

    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);

  }

  componentWillUnmount() {
    window.removeEventListener('resize',  this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      frameSize: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    });
  };

  updatePosition = (x,y) =>  {

    var position = this.bounds({x,y});
    this.setState({
      position
    });

  };

  bounds = (pos) => {

    const { frameSize, scale, canvasRatio } = this.state;

    let boundX = (canvasRatio.width * scale) - frameSize.width;
    let boundY = (canvasRatio.height * scale) - frameSize.height;

    return {
      x: pos.x > 0 ? 0 : pos.x < -boundX ? -boundX : pos.x,
      y: pos.y > 0 ? 0 : pos.y < -boundY ? -boundY : pos.y
    }

  };

  openExhibitor = (exhibitor) => {

    const { frameSize } = this.state;

    //ReactGA.event({
    //  category: 'Company',
    //  action: exhibitor.BusinessName
    //});

    const newX = (-1 * exhibitor.x) + (frameSize.width / 4);
    const newY = (-1 * exhibitor.y) + (frameSize.height / 2);
    this.updatePosition( newX, newY);

    this.setState({
      currentExhibitor: exhibitor
    });

  };

  closeExhibitor = () => {
    if(this.state.currentExhibitor !== null){
      this.setState({
        currentExhibitor: null
      });
    }
  };

  adminSavePosition = (_id,x,y) => {

    MongoDBService.updateExhibitor(_id,{x,y}).then(function(res) {
      console.log("SUCCESS");
    }).catch((error) => {
      console.log(error);
    });

  };

  adminSaveLabelPosition = (_id,x,y) => {

    MongoDBService.updateExhibitorLabel(_id,{x,y}).then(function(res) {
      console.log("SUCCESS");
    }).catch((error) => {
      console.log(error);
    });

  };

  adminSavePublished = (_id,published) => {

    MongoDBService.updateExhibitor(_id,{published}).then(function(res) {
      console.log("SUCCESS");
    }).catch((error) => {
      console.log(error);
    });

  };

  adminSaveType = (_id,type) => {

    MongoDBService.updateExhibitor(_id,{type}).then(function(res) {
      console.log("SUCCESS");
    }).catch((error) => {
      console.log(error);
    });

  };

  handlePanClick = (direction) => {

    const { position, canvasRatio } = this.state;

    if (direction === 'up') {
      const y = position.y + canvasRatio.height * 0.15;
      this.updatePosition(position.x, y)
    } else if(direction === 'down') {
      const y = position.y - canvasRatio.height * 0.15;
      this.updatePosition(position.x, y)
    } else if(direction === 'left') {
      const x = position.x + canvasRatio.width * 0.15;
      this.updatePosition(x, position.y)
    } else if(direction === 'right') {
      const x = position.x - canvasRatio.width * 0.15;
      this.updatePosition(x, position.y)
    }

  };

  openStage = () => {
    console.log('openStage');
    this.setState({
      lightboxStage: true
    });
  };

  openAerostat = () => {};

  handleZoom = (zoomIn = false) => {

    const { maxZoom } = this.props;
    const { scale, frameSize, canvasRatio, canvasSize, position } = this.state;

    const newScale = zoomIn ? scale + 0.1 : scale - 0.1;

    if (newScale > maxZoom || (canvasRatio.width * newScale < frameSize.width || canvasRatio.height * newScale < frameSize.height)) {
      return false;
    }

    const positionX = Math.abs(position.x) + window.innerWidth * 0.5;
    const positionY = Math.abs(position.y) + window.innerHeight * 0.5;

    const percPositionX = positionX / (canvasSize.width * scale);
    const percPositionY = positionY / (canvasSize.height * scale);

    const newXPos = (canvasSize.width * newScale) * percPositionX * -1 + window.innerWidth * 0.5;
    const newYPos = (canvasSize.height * newScale) * percPositionY * -1 + window.innerHeight * 0.5;

    this.setState({
      scale: newScale,
      position: {
        x: newXPos,
        y: newYPos
      }
    });

  }

  render() {

    const { frameSize, scale, canvasRatio, canvasSize } = this.state;
    const { language, config } = this.props;
    const editing = config.editing && ARR_GODS.includes(this.props.user.email);

    return (
      <Frame
        width={frameSize.width}
        height={frameSize.height}
      >
        <StageContainer
          windowSize={{
            width: window.innerWidth,
            height: window.innerHeight
          }}
          canvasRatio={canvasRatio}
          canvasSize={canvasSize}
          editing={editing}
          onBounds={this.bounds}
          onDrag={this.updatePosition}
          onExhibitorClick={this.openExhibitor}
          onExhibitorClose={this.closeExhibitor}
          onStageClick={this.openStage}
          onAerostatClick={this.openAerostat}
          onAdminSavePosition={this.adminSavePosition}
          onAdminSaveLabelPosition={this.adminSaveLabelPosition}
          onAdminSavePublished={this.adminSavePublished}
          onAdminSaveType={this.adminSaveType}
          canvasScale={scale}
          {...this.state}
        />
        <Topbar
          setUser={(user) => this.props.setUser(user)}
          setLanguage={(language) => this.props.setLanguage(language)}
          user={this.props.user}
          language={this.props.language}
          openExhibitor={(exhibitor) => this.openExhibitor(exhibitor)}
          currentEvent = {this.props.currentEvent}
          nextEvent = {this.props.nextEvent}
          openStage={() => this.openStage()}
          openAerostat={() => this.openAerostat()}
        />
        <MapUI
          onPanClick={this.handlePanClick}
          onZoom={this.handleZoom}
        />
        <div className={`World_SheetContainer ${this.state.currentExhibitor ? 'show' : ''}`}>
          <Sheet
            exhibitor={this.state.currentExhibitor}
            language={language}
            onCloseClick={this.closeExhibitor}
          />
        </div>
        {(editing) ? <EditingNotification>EDITING by {this.props.user.email}</EditingNotification> : null}
        <StageLightbox
          language={this.props.language}
          user={this.props.user}
          open={this.state.lightboxStage}
          currentEvent = {this.props.currentEvent}
          callbackClose={() => this.setState({lightboxStage: false})}
        />
      </Frame>
    );
  }
}

export default World;
