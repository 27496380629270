import React, { Component } from 'react';
import styled from 'styled-components';
import OuterLanguageSelector from './OuterLanguageSelector';

import logoBySrc from '../assets/logo_by.png';

const Main = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url("/assets/background-landing.jpg?v=20201209205100");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-color: #052572;
  background-size: 60%;
  text-align: center;
  z-index: 3;
`;

const Container = styled.div`
  display: inline-block;
  text-align: left;
  max-width: 90vw;
`;
const Title = styled.div`
  color: #ffffff;
  font-family: "Raleway";
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 60px;
  font-size: 120px;
  @media (max-width: 1500px) {
    font-size: 90px;
  }
  @media (max-width: 768px) {
    font-size: 50px;
  }
`;
const LogoBy = styled.img`
  width: 175px;
  height: 56px;
  position: relative;
  @media (min-width: 769px) {
    top: 29px;
    left: -7px;
  }
`;
const Date = styled.div`
  color: #ffffff;
  font-family: "Raleway";
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 70px;
  @media (max-width: 768px) {
    font-size: 40px;
  }
`;
const Hashtag = styled.div`
  color: #ffffff;
  font-family: "Raleway";
  font-weight: 400;
  font-size: 60px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 35px;
  }
`;
const Time = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 70px;
`;
const Slot = styled.div`
  text-align: center;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
`;
const Value = styled.div`
  color: #ffffff;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 50px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 35px;
  }
`;
const Label = styled.div`
  color: #ffffff;
  font-family: "Raleway";
  font-weight: 300;
  font-size: 30px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
// const Text = styled.div`
//   color: #ffffff;
//   font-family: "Raleway";
//   font-weight: 400;
//   font-size: 30px;
//   text-transform: uppercase;
// `;
const Button = styled.a`
  color: #ffffff;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 30px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 20px 40px;
  border: 3px solid #fff;
  border-radius: 5px;
  background-color: #052572;
  box-shadow: 4px 4px 20px -10px rgba(0,0,0,0.75);

  &:hover{
    color: #052572;
    background-color: #ffffff;
  }
`;


class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language,
      days: props.countdown.days,
      hours: props.countdown.hours,
      minutes: props.countdown.minutes,
      seconds: props.countdown.seconds
    };
  }

  static getDerivedStateFromProps(nextProps, prevState){
     if(nextProps.countdown!==prevState.countdown || nextProps.language!==prevState.language){
       // console.log("asd");
       return {
         language: nextProps.language,
         days: nextProps.countdown.days,
         hours: nextProps.countdown.hours,
         minutes: nextProps.countdown.minutes,
         seconds: nextProps.countdown.seconds
       };
    }
    else return null;
  }

  render() {
    return (
      <Main>

        <OuterLanguageSelector
          setLanguage={(language) => this.props.setLanguage(language)}
          language={this.state.language}
        />

        <Container>
          <Title>Fee Only 2020 Limited<LogoBy src={logoBySrc}/></Title>
          <Date>{ this.state.language === "it" ? "16 Dicembre" : "16th December" }</Date>
          <Hashtag></Hashtag>

          <Time>
            <Slot>
              <Value>
                {this.state.days}
              </Value>
              <Label>{ this.state.language === "it" ? "Giorni" : "Days" }</Label>
            </Slot>
            <Slot>
              <Value>
                {this.state.hours}
              </Value>
              <Label>{ this.state.language === "it" ? "Ore" : "Hours" }</Label>
            </Slot>
            <Slot>
              <Value>
                {this.state.minutes}
              </Value>
              <Label>{ this.state.language === "it" ? "Minuti" : "Minutes" }</Label>
            </Slot>
            <Slot>
              <Value>
                {this.state.seconds}
              </Value>
              <Label>{ this.state.language === "it" ? "Secondi" : "Seconds" }</Label>
            </Slot>
          </Time>

          <Button href={this.state.language === "it" ? "https://registrazione.feeonlysummit.com/it" : "https://registrazione.feeonlysummit.com/it"}>
            {this.state.language === "it" ? "Registrati ora" : "Register now"}
          </Button>

          {/*<Text>Tutti i contenuti e le novità esclusive in anteprima?<br/>Iscriviti alla nostra newsletter!</Text>*/}
        </Container>
      </Main>
    );
  }
}

export default Landing;
