import React, { Component } from 'react';
import axios from 'axios';
import World from './components/World';
import Login from './components/Login';
import Landing from './components/Landing';
import Closed from './components/Closed';
import Countdown from 'react-countdown';
//import ReactGA from 'react-ga';

import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    var userLang = navigator.language || navigator.userLanguage;

    console.log(userLang);

    this.landing = false;

    this.state = {
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
      
      //language: localStorage.getItem('language') ? localStorage.getItem('language') : userLang.substring(0, 2) === "it" ? "it" : "en",
      language: 'it',

      config: {
        editing: false,
        publishingDate : '2021-01-31T09:00:00',
        days : 1,
        hourStart : 9,
        hourEnd : 24,
      },
      maxZoom: 2,
      numTrees: 0,
      canvasRatio: {
        // devono coincidere con le misure di world.png
        width: 2000,
        height: 1125
      },
      events: [],
    }
    
    this.currentEvent = null;
    this.nextEvent = null;
  }

  getConfig = () => {
    const that = this;
    axios.get('/config/config.json?' + Date.now(), {
      params: {
        
      }
    })
    .then(function (response) {
      console.log(response.data);
      that.setState({config : response.data});
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });      
  }

  getEvents = () => {
    const that = this;
    axios.get('/config/events.json?' + Date.now(), {
      params: {
        
      }
    })
    .then(function (response) {
      console.log(response.data);
      that.setState({events : response.data});
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });      
  }

  componentDidMount() {

    //ReactGA.initialize('UA-169206496-1');
    //ReactGA.pageview(window.location.pathname + window.location.search);

    this.getConfig();
    this.getEvents();
  }

  setUser(user) {
    console.log(user);
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({
      user: user
    });
  }

  setLanguage(language) {
    localStorage.setItem('language', language);
    this.setState({
      language: language
    });
  }

  countdownRender(props) {
    if ((props.hour >= this.state.config.hourStart) && (props.hour < this.state.config.hourEnd)) {
      if (this.state.user) {

        let that = this;
        let currentTime = props.day * 24 * 60 + props.hour * 60 + props.minute;
        let currentEvent = null;
        let nextEvent = null;
      
        this.state.events.forEach(function(item, index){
          let startTime = item.timestart.day*24*60+item.timestart.hour*60+item.timestart.minute;
          let endTime = item.timeend.day*24*60+item.timeend.hour*60+item.timeend.minute;
          if(currentTime < endTime && currentEvent == null){
            currentEvent = item;
            if(that.state.events.length > index+1){
              nextEvent = that.state.events[index+1];
            }
          }
        });
        this.currentEvent = currentEvent;
        this.nextEvent = nextEvent;

        return (
          <World
            setUser={(user) => this.setUser(user)}
            setLanguage={(language) => this.setLanguage(language)}
            {...this.state}
            //time={props}
            currentEvent = {this.currentEvent}
            nextEvent = {this.nextEvent}
          />
        );
      } else {
        return (
          <Login
            setUser={(user) => this.setUser(user)}
            setLanguage={(language) => this.setLanguage(language)}
            language={this.state.language}
          />
        );
      }
    } else {
      return (
        <Closed
          setLanguage={(language) => this.setLanguage(language)}
          language={this.state.language}
        />);
    }
  }

  render() {
    if (this.landing) {
      return(
        <>
          <Countdown
            intervalDelay={1000}
            date={new Date(this.state.config.publishingDate).getTime()}
            renderer={(props) => {
              if (!props.completed) {
                return(
                  <Landing
                    setLanguage={(language) => this.setLanguage(language)}
                    language={this.state.language}
                    countdown={props}
                  />
                );
              } else {
                return null;
              }
            }}
          />
        </>
      );
    } else {
      return(
        <>
          <Countdown
            intervalDelay={5000}
            date={new Date(this.state.config.publishingDate).getTime() + (this.state.config.days + 1) * 24 * 60 * 60 *1000 - this.state.config.hourStart * 60 * 60 *1000}
            renderer={(props) => this.countdownRender({day: 2-props.days, hour: 23-props.hours, minute: 59-props.minutes})}
          />
        </>
      );
    }
  }
}

export default App;
