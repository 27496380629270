import React, { Component } from 'react';
import styled from "styled-components";
import FsLightbox from "../plugins/fslightbox-react";
import axios from "axios";

import FlowershelfGallery from "./FlowershelfGallery";

const Wrapper  = styled.div`
	width: calc(100% - 80px);
	margin: 0 auto 20px;
`;

const Element  = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
`;
const Image  = styled.img`
	object-fit: cover;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  box-shadow: 0px 2px 3px #00000029;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
`;
const Title  = styled.h2`
	margin: 0
`;
const Description  = styled.div`
	margin-top: 3px;
	& a{
		color: #ffffff;
	}
`;


class SheetGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIndex: 0,
      isLightboxOpen: false,
			arrFlowershelf: [],
			arrLightbox: [],
			images: props.images,
			language: props.language
    }
  }


	componentDidMount() {
		// console.log("componentDidMount");
		this.compose();
	}

	componentDidUpdate(prevProps) {
		if (this.props.images !== prevProps.images) {
			this.compose();
  	}
	}

  static getDerivedStateFromProps(nextProps, prevState){
	 // console.log("getDerivedStateFromProps check");
     if(nextProps.images!==prevState.images || nextProps.language!==prevState.language){
	 		// console.log("getDerivedStateFromProps ok");
       return {
         images: nextProps.images,
         language: nextProps.language

       };
    }
    else return null;
  }

	async compose(){
		this.setState({
			arrFlowershelf: null
		});
		let arrFlowershelf = [];
		let arrLightbox = [];
		// console.log(this.state.images);
		const contentSizes = await this.getGallerySizes(this.state.images);
		// console.log(contentSizes);

		this.state.images.forEach((item, index) => {
			let thumb = item.Url;
			let zoom = item.Url;
			arrLightbox.push(zoom);

			let rowIndex = Math.floor(index/2);
			if(true){
				if(!(index !== 0 && index === this.state.images.length - 1 && index%3 === 0) && !arrFlowershelf[rowIndex]){
					arrFlowershelf[rowIndex] = [];
				}
			}
			let obj = {};
			if(contentSizes){
				obj.width = contentSizes[index].width;
				obj.height = contentSizes[index].height;
			}else{
				obj.width = 4;
				obj.height = 3;
			}
			obj.content = (
				<Element onClick={() => this.openLightbox(index)} >
					<Image src={thumb} alt={item.Title[this.state.language]} title={item.Title[this.state.language]}/>
					{/*
					<Description>
						<Title>{item.Title[this.state.language]}</Title>
						<Text>{item.Description[this.state.language]}</Text>
					</Description>
						*/}
				</Element>
			);

	    if(true){
				if(index !== 0 && index === this.state.images.length - 1 && index%3 === 0){
					arrFlowershelf[rowIndex - 1].push(obj);
				}else{
					arrFlowershelf[rowIndex].push(obj);
				}
			}else{
				arrFlowershelf[index] = [];
				arrFlowershelf[index].push(obj);
			}
		});
		this.setState({
			arrFlowershelf,
			arrLightbox
		});
	}

	async getGallerySizes(photos){
		// console.log(photos);
		return new Promise((resolve, reject) => {
			if (photos.length > 0) {
				var images = [];
				photos.forEach((item, index) => {

					let obj = {};
					obj.name = "" + index;
					obj.sourceType = "CDN";
					obj.source = item.Url.replace("https://cdn.ozwol.cloud/W69KP84S2Q/","");
					images.push(obj);

				});
				// console.log(images);
				if(images.length > 0){
					axios.defaults.headers.common['Content-Type'] = "application/json";
					axios.defaults.headers.common['Ozwol-ApiKey'] = "aefde7d9-82cf-4210-bfe8-5eec110ea2d2";
					axios.defaults.headers.common['Ozwol-ProjectId'] = "W69KP84S2Q";
					axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
					axios.post("https://api.ozwol.cloud/v1/executeScript", {
						"scriptName": "get_shape",
						"destination": {
							"type": "INLINE"
						},
						"parameters": {
							"image": images
						}
					}).then(function (result) {
						let sizes = [];
						result.data.results[0].forEach(function(item,index){
							let key = Object.keys(item)[0];
								// console.log(key);
								// console.log(item[key]);
								sizes[key] = item[key];
						})
						resolve(sizes);

					}).catch(function (error) {
						console.log(error);
						resolve(null);
					});
				} else {
					resolve(null);
				}
			} else {
				resolve(null);
			}

		})


	}


	openLightbox(i){
		// console.log(!this.state.isLightboxOpen);
		// console.log(i+1);
		this.setState({
			isLightboxOpen: !this.state.isLightboxOpen,
			lightboxIndex: i+1
		});
	}

	render(){
		// console.log(this.state.arrLightbox);

		const sources = this.state.arrLightbox;
		let captions = [];
		const slide = this.state.lightboxIndex;

		if (sources.length) {

			this.state.images.map(item => {
				captions.push(
					<>
						<Title style={{margin: 0}}>{item.Title[this.state.language]}</Title>
						<Description dangerouslySetInnerHTML={{__html: item.Description[this.state.language]}} />
					</>
				)
				return null;
			})

		}

		return (
			<Wrapper>
				{this.state.arrFlowershelf ?
					<>
						<FlowershelfGallery
							images={this.state.arrFlowershelf}
							fullRows={true}
							gutter={20}
						/>
						{sources.length ? (
							<FsLightbox
								toggler={this.state.isLightboxOpen}
								sources={sources}
								type={'image'}
								captions={captions}
								slide={slide}
								disableLocalStorage={true}
							/>
						) : null}
					</>
				 : null }
			</Wrapper>
		);
	}
}


export default SheetGallery;
