import React, {Component} from 'react';
import {Group, Text, Circle, Rect} from 'react-konva';

import InteractiveImage from './InteractiveImage';
import StaticImage from './StaticImage';
import RemoteImage from './RemoteImage';

class Building extends Component {
  constructor(props) {
    super(props);
    this.onChangePublished= props.onChangePublished;
    this.onChangeType = props.onChangeType;
    this.state = {
      isHover: false,
      published: props.published,
      type: props.id.substring(props.id.lastIndexOf('-') + 1),
      buildingWidth: 0,
      buildingHeight: 0,
      logoHeight: 0,
      logoWidth: 0
    }
  }

  toggleHover = (value) => {

    if (this.props.editing) return;
    this.setState({
      isHover: value
    })

  }

  updateTooltipPosition({width, height}){

    if(this.state.buildingHeight === 0){
      this.setState({
        buildingWidth: (width/ 2),
        buildingHeight: (height / 2)
      })
    }

  }

  updateLogoSize(size){

    if(this.state.logoWidth === 0){
      if(size.width * 60 / size.height < 190){
        this.setState({
          logoHeight: 60,
          logoWidth: (size.width * 60 / size.height)
        })
      }else{
        this.setState({
          logoHeight: (size.height * 190 / size.width),
          logoWidth: 190
        })
      }

    }
  }

  togglePublished(){
    this.onChangePublished(this.state.published === 0 ? 1 : 0);
    this.setState({
      published: this.state.published === 0 ? 1 : 0
    })
  }

  toggleType(){
    let arrTypes = ["a","b","c","d","e","f","g","h","i","m","n","o","p"];//,"q"];
    // console.log(this.state.type);

    let nextIndex = arrTypes.indexOf(this.state.type.replace(/[0-9]/g, '')) + 1;
    if(nextIndex >= arrTypes.length){
      nextIndex = 0;
    }
    let nextType = arrTypes[nextIndex][0];

    let level = this.state.type[1];
    this.onChangeType(nextType);
    this.setState({
      type: nextType+level,
      id: this.props.id.replace("-"+this.state.type, "-"+nextType+level)
    })
  }

  render() {

    const { exhibitor, editing, onBuildingMoved, onLabelMoved, onClick, labelPosition, position, custom } = this.props;
    const { buildingHeight, isHover, logoWidth, logoHeight } = this.state;

    return (
      <Group
        x={position.x}
        y={position.y}
        draggable={editing}
        onDragEnd={e => {
          console.log('TEST', e.currentTarget.attrs.x, position.x, e.currentTarget.attrs.y, position.y);
          onBuildingMoved(e)
        }}
        opacity={this.state.published ? 1 : editing ? 0.5 : 0 }
      >
        <InteractiveImage
          id={this.props.id+"-main"}
          handleClick={e => !editing ? onClick(e) : null}
          handleEnter={() => this.toggleHover(true)}
          handleLeave={() => this.toggleHover(false)}
          info={(log) => this.updateTooltipPosition(log) }
          visible={true}
        />
        {exhibitor ?
          <Group
            x={0}
            y={-buildingHeight-50}
            visible={isHover}
          >
            <StaticImage
              x={0}
              y={0}
              id={"tooltip"}
              visible={true}
            />
            <RemoteImage
              x={-(logoWidth/2)}
              y={-20-(logoHeight/2)}
              width={logoWidth}
              height={logoHeight}
              url={exhibitor.Logo}
              visible={true}
              info={(log) => this.updateLogoSize(log) }
            />
            <Text
              x={-95}
              y={28}
              text={exhibitor.BusinessName}
              fill={"#052572"}
              align="center"
              width={190}
              wrap={"none"}
              tSize={18}
              ellipsis={"ellipsis"}
            />
          </Group>
        : null }
        {custom ?
          <Group
            x={0}
            y={-buildingHeight-5}
            visible={isHover}
          >
            <StaticImage
              x={0}
              y={0}
              id={"tooltip-text"}
              visible={true}
            />
            <Text
              x={-95}
              y={-10}
              text={custom.Description}
              fill={"#052572"}
              align="center"
              width={190}
              wrap={"none"}
              tSize={18}
              ellipsis={"ellipsis"}
            />
          </Group>
        : null }
        {editing ?
          <>
            <Group
              x={-80}
              y={-40}
              onClick={() => this.togglePublished()}
            >
              <Circle radius={22} fill="#052572" />
              <Text text="On/Off" x={-22} y={-5} fill="white" tSize={15} align="center" width={44}/>
            </Group>
            <Group
              x={-80}
              y={15}
              onClick={() => this.toggleType()}
            >
              <Circle radius={22} fill="#052572" />
              <Text text={"Type ("+this.state.type+")"} x={-22} y={-10} fill="white" tSize={15} align="center" width={44} />
            </Group>
          </>
        : null }
        {!isHover && exhibitor ? (
          <Group
            visible={false}
            draggable={editing}
            onDragEnd={e => onLabelMoved(e)}
            x={labelPosition ? labelPosition.x : 0}
            y={labelPosition ? labelPosition.y : -buildingHeight - 50 }
            skew={{
              x: 0,
              y: 0.58
            }}
          >
            <Rect
              fill={'#000000'}
              strokeWidth={2}
              stroke={'#ffffff'}
              cornerRadius={3}
              width={110}
              height={30}
              x={-10}
            />
            <Text
              x={-5}
              y={8}
              wrap={"none"}
              tSize={18}
              ellipsis={"ellipsis"}
              text={exhibitor.BusinessName}
              fill={'#ffffff'}
              align={'center'}
              width={100}
            />
          </Group>
        ) : null}
      </Group>


    );
  }
}

export default Building;
