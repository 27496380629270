import React from 'react';
import logo from '../../assets/logo.png';
import Search from '../Search/Search';
import Guide from '../Guide/Guide';
import Menu from '../Menu/Menu';
import './style.scss';

const Topbar = ({setUser, setLanguage, user, language, openExhibitor, currentEvent, nextEvent, openStage, openAerostat}) => {

  return (
    <div className={'Topbar_Wrapper'}>
      <div className={'Topbar_Logo'}>
        <img src={logo} alt="Logo" />
      </div>
      <Search
        openExhibitor={(exhibitor) => openExhibitor(exhibitor)}
        language={language}
      />
      <div className={'Topbar_Right'}>
        <Guide
          currentEvent={currentEvent}
          nextEvent={nextEvent}
          language={language}
          openStage={() => openStage()}
          openAerostat={() => openAerostat()}
        />
        <Menu
          setUser={(user) => setUser(user)}
          setLanguage={(language) => setLanguage(language)}
          user={user}
          language={language}
        />
      </div>
    </div>
  );
};


export default Topbar;
