import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import SheetGallery from './SheetGallery';
import SheetVideo from './SheetVideo';

import imageCoverDefault from '../assets/cover.jpg'
import imageCoverMedia from '../assets/cover-media.png'
import imageCoverSponsor from '../assets/cover-sponsor.png'

import iconPlace from '../assets/place.svg'
import iconWeb from '../assets/web.svg'
import iconMail from '../assets/mail.svg'
import iconPhone from '../assets/phone.svg'

import iconFacebook from '../assets/facebook.svg'
import iconInstagram from '../assets/instagram.svg'
import iconEcommerce from '../assets/ecommerce.svg'
import iconYoutube from '../assets/youtube.svg'
import iconTwitter from '../assets/twitter.svg'
import iconLinkedin from '../assets/linkedin.svg'
import iconContactPhone from '../assets/tel.svg'
import iconContactMail from '../assets/mail.svg'

import './sheet.scss';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow-x: none;
  overflow-y: auto;
`;
const Map = styled.div`
  width: 100%;
  height: 296px;
  background-color: #666666;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: right;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
`;
const Logo = styled.div`
  position: relative;
  width: 50%;
  padding: 5px;
  overflow: hidden;
  text-align: center;

  & > img{
    max-width: 70%;
    max-height: 120px;
  }
`;
const Card = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 2px 3px #00000029;
  background: #ffffff;
  border-radius: 5px;
  padding: 30px;
  margin-top: -30px;
  margin-bottom: 10px;
`;
const Geo = styled.div`
  margin-bottom: 15px;
`;
const Country = styled.div`
  display: inline-block;
  background-color: #00AAC9;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 3px;
  text-transform: uppercase;
  margin-right: 5px;
  font-weight: 700;
  font-size: 12px;
`;
const Region = styled.div`
  display: inline-block;
  background-color: #ACACAC3B;
  color: #585858;
  padding: 5px 8px;
  border-radius: 3px;
  font-weight: 700;
  font-size: 12px;
`;
const Title = styled.div`
  color: #052572;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 30px;
`;
const Category = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #707070;
  margin-bottom: 10px;
`;
const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #555555;
  margin-bottom: 30px;
`;
const ContactBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  margin-bottom: 30px;

  @media(max-width: 1023px) {
    flex-direction: column;
  }
`;
const ContactBox = styled.div`
  background-color: #F8F8F8;
  border-radius: 3px;
  padding: 15px 10px;
  flex-basis: calc(50% - 10px);
  flex-grow: 0;
  flex-shrink: 0;
  @media(min-width: 1024px) {
  padding: 15px 30px;
  }

`;
const AddressRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
`;
const AddressIcon = styled.div`
  position: relative;
  top: -3px;
  font-weight: 700;
  color: #052572;
  flex-grow: 0;
  flex-shrink: 0;
  width: 25px;
`;
const AddressValue = styled.div`
  font-weight: 400;
  font-size: 17px;
  color: #555555;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 10px;

  & > a{
    color: inherit;
    text-decoration: none;
  }
  & > a:hover{
    color: inherit;
    text-decoration: underline;
  }
`;
const IconPlace = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const IconWeb = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const IconPhone = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
`;

const Social = styled.a`
  margin-left: 5px;
`;

const IconSocial = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PeopleRow = styled.div`
  border-bottom: 1px solid #dedede;
  padding-bottom: 3px;
  margin-bottom: 3px;
`;
const PeopleLabel = styled.div`
  font-weight: 700;
  color: #052572;
  width:100%;
  margin-bottom: 3px;
`;
const PeopleValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const PeopleName= styled.div`
  font-weight: 400;
  color: #555555;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
`;
const PeopleIcons= styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
`;
const PeoplePhone = styled.div`
  position: relative;
  top: -3px;
  font-weight: 700;
  color: #052572;
  flex-grow: 0;
  flex-shrink: 0;
  width: 25px;
  margin-left: 5px;
`;
const PeopleMail = styled.div`
  position: relative;
  top: -3px;
  font-weight: 700;
  color: #052572;
  flex-grow: 0;
  flex-shrink: 0;
  width: 25px;
`;
const PeopleIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
// const ChatCta = styled.button`
//   background-color: #052572;
//   color: #ffffff;
//   display: inline-block;
//   border-radius: 3px;
//   font-family: Roboto;
//   font-weight: 700;
//   font-size: 20px;
//   text-align: center;
//   padding: 12px 20px;
//   text-transform: uppercase;
//   cursor: pointer;
//   border: none;
// `;
const BrandsContainer = styled.div`
  margin-top: 50px;
`;
const BrandsTitle = styled.div`
  color: #707070;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const BrandsList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const BrandLink = styled.a`
  width: calc(33% - 10px);
  padding: 10px;
`;
const Brand = styled.img`
  margin-bottom: 15px;
  height: 100px;
  box-shadow: 0px 2px 3px #00000029;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  object-fit: contain;
  width: 100%;
`;


const DocumentsList = styled.div`
  margin: 40px;

`;
const Document = styled.div`
  width: 100%;
  background-color: #F4F4F4;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
`;
const DocumentTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: ##707070;
`;
const DocumentButton = styled.a`
  background-color: #052572;
  color: #ffffff;
  display: inline-block;
  border-radius: 3px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 6px 10px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  text-decoration: none;
`;


const Sheet = ({language, exhibitor, onChatClick, onCloseClick}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
    console.log('useEffect');
  });

  if(exhibitor){
    // console.log(playing);
    let imageCover = imageCoverDefault;
    
    if (exhibitor.Padiglione === 'med') {
      imageCover = imageCoverMedia;
    } else if (exhibitor.Padiglione === 'spo') {
      imageCover = imageCoverSponsor;
    }
    

    return (
      <Wrapper>
        <Map image={imageCover}>
          <button onClick={e => onCloseClick()} className={'World_CloseSheet'}></button>
          {exhibitor.Logo ? <Logo><img src={exhibitor.Logo} alt={exhibitor.BusinessName} /></Logo> : "" }
        </Map>
        <Card>
          <Geo>
            {exhibitor.Country[language] ? <Country>{exhibitor.Country[language]}</Country> : "" }
            {exhibitor.City[language] ? <Region>{exhibitor.City[language]}</Region> : "" }
          </Geo>
          <Title>{exhibitor.BusinessName}</Title>
          {exhibitor.Categories.length > 0 ?
            <Category>
              {exhibitor.Categories.map((item, index) =>(
                <span key={index}>{item[language]}{index !== exhibitor.Categories.length - 1 ? ", " : "" }</span>
              ))}
            </Category>
          : "" }
          {exhibitor.Description ? <Description dangerouslySetInnerHTML={{ __html: exhibitor.Description[language] }}></Description> : "" }
          <ContactBoxes>
            <ContactBox>
              <AddressRow>
                <AddressIcon>
                  <IconPlace src={iconPlace} />
                </AddressIcon>
                <AddressValue>{exhibitor.Address}, {exhibitor.Zip} {exhibitor.City[language]} {exhibitor.Province[language]}</AddressValue>
              </AddressRow>
              {exhibitor.Web ?
                <AddressRow>
                  <AddressIcon>
                    <IconWeb src={iconWeb} />
                  </AddressIcon>
                  <AddressValue><a href={exhibitor.Web.Url} rel="noopener noreferrer" target="_blank">{exhibitor.Web.Name}</a></AddressValue>
                </AddressRow>
               : "" }
               {exhibitor.Email ?
                 <AddressRow>
                   <AddressIcon>
                     <IconWeb src={iconMail} />
                   </AddressIcon>
                   <AddressValue><a href={"mailto:"+exhibitor.Email}>{exhibitor.Email}</a></AddressValue>
                 </AddressRow>
                : "" }
               {exhibitor.Phone ?
                 <AddressRow>
                   <AddressIcon>
                     <IconPhone src={iconPhone} />
                   </AddressIcon>
                   <AddressValue><a href={"tel:"+exhibitor.Phone} rel="noopener noreferrer" target="_blank">{exhibitor.Phone}</a></AddressValue>
                 </AddressRow>
                : "" }

                <Socials>
                  {exhibitor.Facebook.Url && exhibitor.Facebook.Url !== "" ?
                    <Social href={exhibitor.Facebook.Url} target="_blank">
                      <IconSocial src={iconFacebook} alt={exhibitor.Facebook.Name} />
                    </Social>
                  : ""}
                  {exhibitor.Instagram.Url && exhibitor.Instagram.Url !== "" ?
                    <Social href={exhibitor.Instagram.Url} target="_blank">
                      <IconSocial src={iconInstagram} alt={exhibitor.Instagram.Name} />
                    </Social>
                  : ""}
                  {exhibitor.Youtube.Url && exhibitor.Youtube.Url !== "" ?
                    <Social href={exhibitor.Youtube.Url} target="_blank">
                      <IconSocial src={iconYoutube} alt={exhibitor.Youtube.Name} />
                    </Social>
                  : ""}
                  {exhibitor.Twitter.Url && exhibitor.Twitter.Url !== "" ?
                    <Social href={exhibitor.Twitter.Url} target="_blank">
                      <IconSocial src={iconTwitter} alt={exhibitor.Twitter.Name} />
                    </Social>
                  : ""}
                  {exhibitor.Linkedin.Url && exhibitor.Linkedin.Url !== "" ?
                    <Social href={exhibitor.Linkedin.Url} target="_blank">
                      <IconSocial src={iconLinkedin} alt={exhibitor.Linkedin.Name} />
                    </Social>
                  : ""}
                  {exhibitor.Ecommerce.Url && exhibitor.Ecommerce.Url !== "" ?
                    <Social href={exhibitor.Ecommerce.Url} target="_blank">
                      <IconSocial src={iconEcommerce} alt={exhibitor.Ecommerce.Name} />
                    </Social>
                  : ""}
                </Socials>
            </ContactBox>
            {exhibitor.CorporateRoles.length > 0 ?
              <ContactBox>
                <ReactTooltip id="people-tooltip" place="top" type="dark" effect="solid" offset={{top: 10, left: 0}} delayHide={500} className="brand-tooltip" backgroundColor="#052572"/>
                {exhibitor.CorporateRoles.map((item, index) =>(
                  <PeopleRow key={index}>
                    <PeopleLabel>{item.Job[language]}</PeopleLabel>
                    <PeopleValue>
                      <PeopleName>
                        {item.NameSurname}
                      </PeopleName>
                      <PeopleIcons>
                        <PeopleMail>
                          {item.Email && item.Email !== "" ?
                            <a href={"mailto:"+item.Email} data-for="people-tooltip" data-tip={item.Email}><PeopleIcon src={iconContactMail} /></a>
                          : ""}
                        </PeopleMail>
                        <PeoplePhone>
                          {item.Phone && item.Phone !== "" ?
                            <a href={"tel:"+item.Phone} data-for="people-tooltip" data-tip={item.Phone}><PeopleIcon src={iconContactPhone} /></a>
                          : ""}
                        </PeoplePhone>
                      </PeopleIcons>
                    </PeopleValue>
                  </PeopleRow>
                ))}
              </ContactBox>
            : "" }
          </ContactBoxes>

          {/*{exhibitor.Chat.Enabled ?
            <center><ChatCta onClick={e => onChatClick({id: exhibitor._id, name: exhibitor.BusinessName})}>{language === "it" ? "Chatta con noi" : "Chat with us"}</ChatCta></center>
          : null }*/}

          {exhibitor.Brands.length > 0 ?
            <BrandsContainer>
              <ReactTooltip id="brand-tooltip" place="top" type="dark" effect="solid" offset={{top: 10, left: 0}} delayHide={500} className="brand-tooltip" backgroundColor="#052572"/>
              <BrandsTitle>{language === "it" ? "Link utili" : "Useful links"}</BrandsTitle>
              <BrandsList>
                {exhibitor.Brands.sort((a, b) => a.DisplayPriority - b.DisplayPriority).map((item, index) =>(
                  <BrandLink href={item.Link} target="_blank" key={index}>
                    <Brand src={item.Url} alt={item.Name} data-for="brand-tooltip" data-tip={item.Name}></Brand>
                  </BrandLink>
                ))}
              </BrandsList>
            </BrandsContainer>
        : "" }
        </Card>

        {exhibitor.Videos.length > 0 ?
          <SheetVideo videos={exhibitor.Videos.sort((a, b) => a.DisplayPriority - b.DisplayPriority)} language={language} />
        : "" }

        {exhibitor.Photos.length > 0 ?
          <SheetGallery images={exhibitor.Photos.sort((a, b) => a.DisplayPriority - b.DisplayPriority)} language={language} />
        : "" }

        {exhibitor.Documents.length > 0 ?
          <DocumentsList>
            {exhibitor.Documents.sort((a, b) => a.DisplayPriority - b.DisplayPriority).map((item, index) =>(
              <Document key={index}>
                <DocumentTitle>{item.Title[language]}</DocumentTitle>
                <DocumentButton href={item.Url[language]} target="_blank">{language === "it" ? "Download" : "Download"}</DocumentButton>
              </Document>
            ))}
          </DocumentsList>
        : "" }
      </Wrapper>
    );
  }else{
    return null;
  }
};


export default Sheet;
