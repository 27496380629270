import React, { Component } from 'react';
import { Image } from 'react-konva';

class InteractiveImage extends Component {

  state = {
    image: null
  }

  constructor(props) {
    super(props);
    this.props = props;

    this.loadImage()
    this.loadCallback = this.loadCallback.bind(this);
    this.imageRef = React.createRef();
  }

  loadImage() {
    const { id } = this.props;
    const img = document.createElement('img');
    img.src = `/assets/${id}.png`;
    img.addEventListener('load', () => {
      this.setState({
        image: img
      }, this.loadCallback)
    });

  }

  loadCallback() {
    this.imageRef.current.cache();
    if (this.props.handleClick || this.props.handleEnter || this.props.handleLeave) {
      this.imageRef.current.drawHitFromCache(0);
      this.props.info({
        width: this.state.image.width,
        height: this.state.image.height,
      });
    }

  }

  render() {

    const {
      handleClick = null,
      handleEnter = null,
      handleLeave = null,
      visible = false,
      style = {}
    } = this.props;

    const {
      image
    } = this.state;

    return (image ? <Image
      offsetX={image ? image.width/2 : 0}
      offsetY={image ? image.height/2 : 0}
      ref={this.imageRef}
      image={image}
      onClick={handleClick}
      onTap={handleClick}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      visible={visible}
      {...style}
    /> : null)

  }

}

export default InteractiveImage;
