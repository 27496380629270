import React, { Component } from 'react';
import styled from 'styled-components';
import iconClear from '../assets/clear.svg';
import logo from '../assets/logo.png';
import logoBySrc from '../assets/logo_by.png';
//import Iframe from 'react-iframe';

import './stagelightbox.scss';

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-image: url("/assets/background-lightbox-stage.jpg?v=202011211234100");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 185px;
  height: 84px;
  object-fit: contain;
  margin-bottom: 1vh;
`;

const Avviso = styled.div`
  color: #ffffff;
  font-family: "Raleway";
  text-transform: uppercase;
  margin-top: 1vh;
  font-size: 40px;
`;

const Close = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
const VideoArea = styled.div`
  margin-top: 2vh;
  margin-bottom: 1vh;
  @media(max-width: 1023px) {
    min-height: 50vh;
  }
`;
const DescriptionArea = styled.div`
  width: 900px;
  max-width: 90%;
  background: #000000;
  text-align: center;
  margin-top: 5vh;
  padding: 50px 25px 25px;
  position: relative;
`;
const Watching = styled.div`
  background-color: #052572;
  border-radius: 3px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 12px 20px;
  text-transform: uppercase;
  color: #ffffff;
  position: absolute;
  width: 300px;
  top: -25px;
  left: calc(50% - 150px);
`;
const Labels = styled.div`
  position: absolute;
  width: 300px;
  top: -25px;
  left: 5%;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Label = styled.div`
  background-color: #052572;
  border-radius: 3px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 12px 20px;
  text-transform: uppercase;
  color: #ffffff;
  width: 300px;
  margin: 0px 5px;

  @media(max-width: 1023px) {
    font-size: 12px;
    padding: 5px 10px;
  }
`;
const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 7px;
  color: #ffffff;
`;
const Abstract = styled.div`
  font-size: 15px;
  font-weight: 500;
  opacity: 72%;
  color: #ffffff;
`;
const Time = styled.div`
  font-size: 35px;
  font-weight: 300;
  margin-top: 20px;
  color: #ffffff;
`;
const LogoBy = styled.img`
  width: 175px;
  height: 56px;
  position: fixed;
  top: 93vh;
  right: 1vh;
  @media (max-height: 768px) {
    top: 90vh;
    right: 4vh;
  }
`;
const WebinarIframe = styled.iframe`
  border:1px solid gray;"
`;

class StageLightbox extends Component {
  constructor(props) {
    super(props);
  }

  close(){
    this.props.callbackClose();
  }

  render() {
    if(this.props.open){
      return (
        <Wrapper>
          <Close onClick={() => this.close()} src={iconClear} />
          <Logo src={logo} alt="Logo" />
          <Avviso>CONSULENZA FINANZIARIA INDIPENDENTE</Avviso>

          { this.props.currentEvent ?
          <>
            <VideoArea>
              {
              <WebinarIframe
                src={this.props.currentEvent.media.value[this.props.language] + "&e=" + this.props.user.email + "&u=" + this.props.user.nickname}
                width="640px"
                height="480px"
                id="zoom-player"
                className="zoom-player"
                display="initial"
                position="relative"
                allow="fullscreen"
              />
                /*
              <Iframe url={this.props.currentEvent.media.value[this.props.language] + "&e=" + this.props.user.email + "&u=" + this.props.user.nickname}
                width="640px"
                height="480px"
                id="zoom-player"
                className="zoom-player"
                display="initial"
                position="relative"
                allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
              />
                */
              }
            </VideoArea>
            <DescriptionArea>
              <Watching>{this.props.language === "it" ? "Stai guardando" : "You are watching"}</Watching>
              <Title>{this.props.currentEvent.title[this.props.language]}</Title>
              <Abstract dangerouslySetInnerHTML={{ __html: this.props.currentEvent.abstract[this.props.language] }}></Abstract>
              <Time>{('0' + this.props.currentEvent.timestart.hour).slice(-2)}:{('0' + this.props.currentEvent.timestart.minute).slice(-2)} - {('0' + this.props.currentEvent.timeend.hour).slice(-2)}:{('0' + this.props.currentEvent.timeend.minute).slice(-2)}</Time>
            </DescriptionArea>
          </>
          :
          <DescriptionArea>
            <Title>{this.props.language === "it" ? "In attesa della prossima conferenza" : "Waiting for next Conference"}</Title>
          </DescriptionArea>
          }
          <LogoBy src={logoBySrc}/>
        </Wrapper>
      );
    }else{
      return null;
    }

  }
};

export default StageLightbox;
