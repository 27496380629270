import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

const RemoteImage = ({x = 0, y = 0, url, width = null, height = null, visible = false, info}) => {
  const [image] = useImage(url);
  React.useEffect(() => {
    if (image) {
        info({
          width: image.width,
          height: image.height,
        });
    }
  }, [image, info]);

  return (
    <Image
      x={x}
      y={y}
      width={width}
      height={height}
      image={image}
      visible={visible}
    />
  );
};


export default RemoteImage;
