import React from 'react';
import {Group, Layer, Stage} from "react-konva";
import Building from './Building';
//import AdvGif from './AdvGif';
import StaticImage from './StaticImage';
import StaticGif from './StaticGif';
import Thing from './Thing';

function StageContainer({ canvasRatio, canvasScale, canvasSize, editing, frameSize, position, exhibitors, trees, showAerostat, onBounds, onDrag, onExhibitorClick, onExhibitorClose, onStageClick, onAerostatClick, onAdminSavePosition, onAdminSaveLabelPosition, onAdminSavePublished, onAdminSaveType }) {

  return (
    <Stage
      width={canvasSize.width}
      height={canvasSize.height}
    >
      <Layer
        scaleX={canvasScale}
        scaleY={canvasScale}
        x={position.x}
        y={position.y}
        draggable={true}
        dragBoundFunc={pos => onBounds(pos)}
        onDragEnd={e => onDrag(e.currentTarget.attrs.x, e.currentTarget.attrs.y)}
      >
        <Group
          offsetX={(canvasRatio.width * -1) / 2}
          offsetY={(canvasRatio.height * -1) / 2}
          onClick={() => onExhibitorClose()}
        >
          <StaticImage id="world" visible={true}/>
        </Group>
{/*
        <Rect
          x={215}
          y={230}
          fill={'#ffcc00'}
        />

        <Group
          x={(492)}
          y={(172)}
        >
          <AdvGif scale={canvasScale} ids="cartellone1|cartellone2|cartellone3|cartellone4" visible={true} size={0.153} side="dx"/>
        </Group>
        <Group
          x={(1774)}
          y={(397)}
        >
          <AdvGif scale={canvasScale} ids="cartellone2|cartellone3|cartellone4|cartellone1" visible={true} size={0.17} side="sx"/>
        </Group>
        <Group
          x={(841)}
          y={(647)}
        >
          <AdvGif scale={canvasScale} ids="cartellone3|cartellone4|cartellone1|cartellone2" visible={true} size={0.206} side="dx"/>
        </Group>
        <Group
          x={(1813)}
          y={(1064)}
        >
          <AdvGif scale={canvasScale} ids="cartellone4|cartellone1|cartellone2|cartellone3" visible={true} size={0.206} side="sx"/>
        </Group>
*/}
        {exhibitors.map((stand, index) => {
          if (stand._id != 'stage') {
            return (
              <Building
                key={`building-${index}`}
                _id={stand._id}
                id={"stand-"+stand.version+stand.level}
                position={{
                  x:stand.x,
                  y: stand.y
                }}
                labelPosition={stand.hasOwnProperty('label') ? stand.label : null}
                exhibitor={stand}
                custom={null}
                onClick={stand.published ? () => onExhibitorClick(stand) : () => {}}
                editing={editing}
                published={stand.published}
                onBuildingMoved={e => onAdminSavePosition(stand._id, e.currentTarget.attrs.x, e.currentTarget.attrs.y)}
                onLabelMoved={e => onAdminSaveLabelPosition(stand._id, e.currentTarget.attrs.x, e.currentTarget.attrs.y)}
                onChangePublished={val => onAdminSavePublished(stand._id, val)}
                onChangeType={val => onAdminSaveType(stand._id, val)}
              />
            )
          } else {
            return (
              <Building
              key={`building-stage`}
              id={"stage"}
                position={{
                  x: stand.x,
                  y: stand.y
                }}
                exhibitor={{BusinessName: "Speech & Tavole Rotonde", Logo: "assets/stage-logo.jpg?2020121230500"}}
                custom={null}
                onClick={() => onStageClick()}
                editing={false}
                published={stand.published}
                onBuildingMoved={e => console.log("stage", e.currentTarget.attrs.x, e.currentTarget.attrs.y)}
                onDragEnd={null}
                onChangePublished={null}
                onChangeType={null}
              />
            )
          }
        })}

        {trees.map((tree, index) => {
          return <Thing
            key={`thinkg-${index}`}
            id="tree-1"
            x={(tree.x)}
            y={(tree.y)}
            visible={true}
          />
        })}

        <Group
          x={(1425)}
          y={(210)}
        >
          <StaticGif
            id="aerostat"
            visible={showAerostat}
            handleClick={() => onAerostatClick()}
          />
        </Group>
      </Layer>
    </Stage>
  );

}

export default StageContainer;
