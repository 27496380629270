import React, { Component } from 'react';
import styled from 'styled-components';
import axios from "axios";
import OuterLanguageSelector from './OuterLanguageSelector';

import logo from '../assets/logo.png'


const Screen = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #052572;
  background-image: url("/assets/background-login.jpg?v=20201209205100");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
const Wrapper = styled.div`
  width: 500px;
  max-width: calc(100% - 10px);
  max-height: 100vh;
  text-align: center;
  padding: 15px 15px 15px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 20px;
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #052572;
  color: #ffffff;
  padding: 110px 50px 40px;
  margin-top: -70px;
  text-align: center;

  @media screen and (max-width: 992px) {
    padding: 110px 20px 40px;
  }
`;
const Logo = styled.div`
  position: relative;
  width: 300px;
  height: 136px;
  max-width: calc(100% - 30px);
  background-color: #FFFFFF;
  margin: 0 auto;
  z-index: 2;
  background-image: url('${props => props.image}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;
const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 7px;
`;
const Subtitle = styled.a`
  font-size: 17px;
  font-weight: 500;
  opacity: 100%;
  text-decoration: underline;
  color: #ffffff;
`;
const Fields = styled.div`
  margin: 40px 0px 20px;
`;
const Input = styled.input`
  background-color: #FFFFFF26;
  color: #e16a54;
  border: 1px solid ${props => props.error ? "red" : "#ffffff"};
  border-radius: 3px;
  font-family: Raleway;
  font-weight: 500;
  font-size: 17px;
  padding: 12px;
  width: 100%;
  margin-bottom: 10px;
`;
const Button = styled.div`
  background-color: #FFFFFF26;
  border-radius: 3px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 12px 20px;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
`;
const Recovery = styled.a`
  display: block;
  font-weight: 500;
  font-size: 13px;
  opacity: 45%;
  color: #ffffff;
  margin-top: 10px;
  &:hover{
    opacity: 100%;
  }
`;

const Notes = styled.div`
  margin: 30px 0px 0px;
  text-align: center;
`;
// const Subject = styled.div`
//   font-weight: 300;
//   font-size: 15px;
//   line-height: 20px;
//   margin-bottom: 6px;
// `;
const Text = styled.div`
  color: #A3A3A3;
  opacity: 80%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: 'none',
      error: false,
      language: props.language
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e){
    e.preventDefault();
    var body = new FormData();
    body.set('email', this.state.email);
    body.set('password', this.state.password);
    axios.post('https://api.vapitaly.com/v1/visitors/authenticate', body, {
      headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      let response = res.data;
      // console.log(response);
      if(response.data && response.data != null){
        // console.log(response.data);
        this.props.setUser(response.data);
      }else{
        this.setState({error: true});
      }
    }, err => {
      // console.log(err);
      this.setState({error: true});
    })
  }

  static getDerivedStateFromProps(nextProps, prevState){
     if(nextProps.language!==prevState.language){
       return {
         language: nextProps.language
       };
    }
    else return null;
  }

  render() {
    return (
      <Screen>
        <OuterLanguageSelector
          setLanguage={(language) => this.props.setLanguage(language)}
          language={this.state.language}
        />
        <Wrapper>
          <Logo image={logo} />
          <Container>
            <Title>{this.state.language === "it" ? "Entra in FeeOnly 2020 Limited" : "Join FeeOnly 2020 Limited"}</Title>
            <Subtitle href={this.state.language === "it" ? "https://registrazione.feeonlysummit.com/it" : "https://registrazione.feeonlysummit.com/en"}>{this.state.language === "it" ? "Registrati su FeeOnlySummit.com" : "Register to FeeOnlySummit.com"}</Subtitle>
            <form onSubmit={this.handleSubmit}>
              <Fields>
                <Input error={this.state.error} placeholder="Email" name="user" type="text" onChange={(e) => this.setState({email: e.target.value, error: false})}/>
                {/*<Input error={this.state.error} placeholder="Password" name="pass" type="password" onChange={(e) => this.setState({password: e.target.value, error: false})} />*/}
              </Fields>
              <Button type="submit" onClick={(e) => this.handleSubmit(e)}>{this.state.language === "it" ? "Entra adesso" : "Join now"}</Button>
            </form>
            {/*<Recovery href={this.state.language === "it" ? "https://www.vapitaly.com/it/recupero-credenziali" : "https://www.vapitaly.com/en/credential-recovery"}>{this.state.language === "it" ? "Ho dimenticato la password" : "I forgot my password"}</Recovery>*/}
            <Notes>
              {/*<Subject>Lorem Ipsum dolor sit</Subject>*/}
              <Text>{this.state.language === "it" ? "Non tutti i dispositivi mobili sono in grado di supportare in modo ottimale l’interfaccia di FeeOnly 2020 Limited: se riscontri dei problemi di visualizzazione, ti consigliamo di goderti l’evento da desktop." : "Not all mobile devices are able to optimally support the Vapitaly 2020 Virtual interface: should you experience any display problems, we recommend that you enjoy the event from your desktop computer."}</Text>
            </Notes>
          </Container>
        </Wrapper>
      </Screen>
    );
  }
}

export default Login;
