import React, { Component } from 'react';
import MongoDBService from '../../data/MongoDBService';
import iconSearch from '../../assets/search.svg'
import iconClear from '../../assets/clear.svg'
import iconMarker from '../../assets/marker.svg'

import './style.scss';

class Search extends Component {

  state = {
    query: '',
    results: [],
    showMobile: false
  }

  constructor(props) {
    super(props);
    this.props = props;

    this.setQuery = this.setQuery.bind(this);
    this.setResults = this.setResults.bind(this);
    this.toggleMobile = this.toggleMobile.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.doSearch = this.doSearch.bind(this);
  }

  setQuery(query) {
    this.setState({
      query
    })
  }

  setResults(results) {
    this.setState({
      results
    })
  }

  toggleMobile() {
    this.setState({
      showMobile: !this.state.showMobile,
      results: this.state.showMobile ? [] : this.state.results,
      query: this.state.query ? [] : this.state.query
    })
  }

  async doSearch(e) {

    const query = e.target.value;
    this.setQuery(query);

    if(query !== "") {
      const res = await MongoDBService.getExhibitors(query, 5, true);
      this.setResults(res);
    } else {
      this.setResults([]);
    }

  }

  clearSearch() {

    this.setState({
      query: '',
      results: []
    })

  }

  render() {

    const { language, openExhibitor } = this.props;
    const { results, query, showMobile } = this.state;

    return (
      <div className={`Search ${showMobile ? 'show-mobile' : ''}`}>
        <img src={iconSearch} className={'Search_DesktopIcon'} alt=""/>
        <button onClick={this.toggleMobile} className={'Search_MobileToggle'}><img src={iconSearch} alt=""/></button>
        <div className={'Search_InputContainer'}>
          <input placeholder={language === "it" ? "Cerca..." : "Search..."} name="search" type="text" value={query} onChange={this.doSearch} autoComplete={'off'} />
          {query.length ? <img src={iconClear} className={'Search_InputContainerClear'} onClick={this.clearSearch} /> : null}
        </div>
        {results.length ? (
          <div className={'Search_Results'}>
            {results.map((item, index) => (
              <div className={'Search_ResultsItem'} key={index} onClick={() => openExhibitor(item)}>
                <img src={iconMarker} alt={''} />
                <div className={'Search_ResultsItemTitle'}>{item.BusinessName}</div>
                <div className={'Search_ResultsItemInfo'}>Info</div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );

  }

}

export default Search;
