import React, { Component } from 'react';
import styled from 'styled-components';
import OuterLanguageSelector from './OuterLanguageSelector';

import logo from '../assets/logo.png'


const Screen = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #052572;
  background-image: url("/assets/background-login.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
const Wrapper = styled.div`
  width: 600px;
  max-width: calc(100% - 10px);
  max-height: 100vh;
  text-align: center;
  padding: 15px 15px 15px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 20px;
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #052572;
  color: #ffffff;
  padding: 110px 50px 40px;
  margin-top: -70px;
  text-align: center;

  @media screen and (max-width: 992px) {
    padding: 110px 20px 40px;
  }
`;
const Logo = styled.div`
  position: relative;
  width: 300px;
  height: 136px;
  max-width: calc(100% - 30px);
  background-color: #FFFFFF;
  margin: 0 auto;
  z-index: 2;
  background-image: url('${props => props.image}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;
const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 7px;
`;
const Subtitle = styled.div`
  font-size: 17px;
  font-weight: 500;
  opacity: 72%;
`;
const Time = styled.div`
  font-size: 50px;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 60px;
`;
const Button = styled.a`
  background-color: #052572;
  color: #FFFFFF;
  text-decoration: none;
  border-radius: 3px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 12px 20px;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
`;
//
// const Notes = styled.div`
//   margin: 30px 0px 0px;
//   text-align: left;
// `;
// const Subject = styled.div`
//   font-weight: 300;
//   font-size: 15px;
//   line-height: 20px;
//   margin-bottom: 6px;
// `;
// const Text = styled.div`
//   color: #A3A3A3;
//   opacity: 80%;
//   font-weight: 400;
//   font-size: 11px;
//   line-height: 16px;
// `;

class Closed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
     if(nextProps.language!==prevState.language){
       return {
         language: nextProps.language
       };
    }
    else return null;
  }

  render() {
    return (
      <Screen>
        <OuterLanguageSelector
          setLanguage={(language) => this.props.setLanguage(language)}
          language={this.state.language}
        />
        <Wrapper>
          <Logo image={logo} />
          <Container>
            <Title>{this.state.language === "it" ? "FeeOnly 2020 Limited è terminato" : "FeeOnly 2020 Limited is over"}</Title>
            {/*<Subtitle>{this.state.language === "it" ? "Seguici durante gli orari di manifestazione" : "Follow us during the event's opening hours"}</Subtitle>
            <Time>10:00 - 18:00</Time>/*}
            <Button href={this.state.language === "it" ? "https://www.vapitaly.com/it/contatti" : "https://www.vapitaly.com/en/contacts"} target="_blank">{this.state.language === "it" ? "Contattaci" : "Contact us"}</Button>

            {/*<Notes>
              <Subject>Lorem Ipsum dolor sit</Subject>
              <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vitae iaculis tortor. Etiam auctor eleifend libero, eu feugiat elit iaculis gravida.</Text>
            </Notes>*/}
          </Container>
        </Wrapper>
      </Screen>
    );
  }
}

export default Closed;
