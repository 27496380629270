import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import './style.scss';

const LogoutItem = styled.div`
  padding: 12px;
  background-color: #405FE6;
  cursor: pointer;
  &:hover{
    background-color: #ffffff;
    color: #000000;
  }
`;

const Menu = ({setUser, setLanguage, user, language}) => {

	const [isOpen, setOpen] = useState(false);
  return (
    <div className={`Menu ${isOpen ? 'show-dropdown' : ''}`} onMouseLeave={() => setOpen(false)}>
      <button className={'Menu_Trigger'} onClick={() => setOpen(!isOpen)}>
        <Avatar email={user.email} name={user.nickname} />
      </button>
      <div className={'Menu_Dropdown'}>
        <LogoutItem onClick={() => setUser(null)}>Logout</LogoutItem>
      </div>
    </div>
  );
};


export default Menu;