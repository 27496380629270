import React, { Component } from 'react';
import { Group } from 'react-konva';

import StaticImage from './StaticImage';


class Thing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      x: props.x,
      y: props.y,
      visible: props.visible
    }
  }

  componentDidMount() {

  }


  render() {
    return (

      <Group x={this.state.x} y={this.state.y}>
        <StaticImage
          id={this.state.id+"-shadow"}
          visible={this.state.visible}
        />
      <StaticImage
          id={this.state.id+"-main"}
          visible={this.state.visible}
        />
      </Group>

    );
  }
}

export default Thing;
