import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import "gifler";

const StaticGif = ({id, handleClick = null, visible = false}) => {
  const [image] = useImage('/assets/'+id+'.gif');
  const imageRef = React.useRef(null);
  const canvas = React.useMemo(() => {
    const node = document.createElement("canvas");
    return node;
  }, []);

  React.useEffect(() => {
    // save animation instance to stop it on unmount
    let anim;
    window.gifler('/assets/'+id+'.gif').get(a => {
      anim = a;
      anim.animateInCanvas(canvas);
      anim.onDrawFrame = (ctx, frame) => {
        ctx.drawImage(frame.buffer, frame.x, frame.y);
        imageRef.current.getLayer().draw();
      };
    });
    return () => anim.stop();
  }, [id, canvas]);



  return (
    <Image
      image={canvas}
      ref={imageRef}
      onClick={handleClick}
      onTap={handleClick}

      offsetX={image ? image.width/2 : 0}
      offsetY={image ? image.height/2 : 0}
      visible={visible}
    />
  );
};


export default StaticGif;
