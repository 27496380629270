import React, { Component } from 'react';
import { Image } from 'react-konva';

class StaticImage extends Component {

  state = {
    image : null
  }

  constructor(props) {
    super(props);
    this.props = props;

    const image = document.createElement('img');
    image.src= `/assets/${this.props.id}.png`;
    image.addEventListener('load', () => {
      this.setState({
        image
      })
    })

  }

  render() {

    const { visible } = this.props;
    const { image } = this.state;

    return image ? <Image
      offsetX={image ? image.width/2 : 0}
      offsetY={image ? image.height/2 : 0}
      image={image}
      visible={visible}
    /> : null;

  }

}

export default StaticImage;
