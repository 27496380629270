import axios from "axios";

export class MongoDBService {

  async getExhibitors(query = null, limit = null, onlyPublished = false) {
    let arrStands = [];

    let q = onlyPublished ? 'published=1' : '';
    const contentBuildings = await axios.get('https://api.vapitaly.com/mongodb/search/buildings?'+q+'&limit=9999');
    //console.log(contentBuildings.data);

    q = query ? 'BusinessName=/'+query+'/i' : '';
    //console.log('q='+q);
    let l = limit ? limit: 9999;
    const contentExhibitors = await axios.get('https://api.vapitaly.com/mongodb/search/exhibitors?'+q+'&limit='+l);
    //console.log(contentExhibitors.data);

    let createdObjs = [];
    contentExhibitors.data.forEach(async (item, i) => {
      let building = contentBuildings.data.filter(result => result._id === item._id)[0];
      //console.log(building);

      let createdObj = {};
      if(!building && !onlyPublished){
        createdObj._id = item._id;
        createdObj.x = 100;
        createdObj.y = 350;
        createdObj.label = (building && building.hasOwnProperty('label')) ? building.label : null;
        createdObj.type = "a";
        createdObj.published = 0;
        createdObjs.push(createdObj);
      }
      if(building || !onlyPublished){
        let exhibitor = item;
        exhibitor.index = item._id;
        exhibitor.version = building ? building.type : createdObj.type;
        exhibitor.level = exhibitor.BuildingSize; //building.size;
        exhibitor.x = parseInt(building ? building.x : createdObj.x);
        exhibitor.y = parseInt(building ? building.y : createdObj.y);
        exhibitor.label = building.label;
        exhibitor.published = parseInt(building ? building.published : createdObj.published);

        arrStands.push(exhibitor);
      }
    });

    /*
    // 2020.12.28
    // commentato alla fine della manifestazione perché lo stage diventa un espositore
    if (limit === null) {
      // aggiungo lo stage alla lista dei building per fare in modo che il tooltip non si sovrapponga agli altri
      let stage = {};
      stage._id = 'stage';
      stage.BusinessName = 'stage';
      stage.index = stage._id;
      stage.x = 1010;
      stage.y = 515;
      stage.published = true;
      arrStands.push(stage);
    }
    */

    arrStands.sort((a,b) => a.x - b.x).sort((a,b) => a.y - b.y);

    axios.post('https://api.vapitaly.com/mongodb/save/buildings', createdObjs)
    .then(function (response) {
      //console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

    return arrStands;
  }

  async updateExhibitor(id, updates) {

    return new Promise((resolve, reject) => {

      axios.get('https://api.vapitaly.com/mongodb/search/buildings?_id=/' + id + '/&limit=1')
      .then(function(content) {
        let updatedObj = {};

        updatedObj._id = id;
        if(updates.hasOwnProperty("x")){
          updatedObj.x = updates.x;
        }else{
          updatedObj.x = content.data[0].x;
        }
        if(updates.hasOwnProperty("y")){
          updatedObj.y = updates.y;
        }else{
          updatedObj.y = content.data[0].y;
        }
        if(updates.hasOwnProperty("type")){
          updatedObj.type = updates.type;
        }else{
          updatedObj.type = content.data[0].type;
        }
        if(updates.hasOwnProperty("published")){
          updatedObj.published = updates.published;
        }else{
          updatedObj.published = content.data[0].published;
        }

        var updatedObjs = [];
        updatedObjs.push(updatedObj);

        axios.post('https://api.vapitaly.com/mongodb/save/buildings', updatedObjs)
        .then(function (response) {
          //console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
        });

      }).catch((error) => {
        reject(error);
      });
    });
  }

  async updateExhibitorLabel(id, updates) {

    const updatedObj = Object.assign({}, {
      _id: id,
      label: {
        x: updates.x,
        y: updates.y
      }
    });

    var updatedObjs = [];
    updatedObjs.push(updatedObj);

    return new Promise((resolve, reject) => {

      axios.post('https://api.vapitaly.com/mongodb/save/buildings', updatedObjs)
      .then(function (response) {
        //console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });

    });
  }

}

export default new MongoDBService();
