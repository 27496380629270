import React from 'react';
import styled from 'styled-components';

import panController from '../assets/pan_off.svg'

const PanController = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100px;
  height: 100px;
`;
const PanControllerImage = styled.img`
  width: 100%;
  height: 100%;
`;

const PanControllerButtonTop = styled.button`
  position: absolute;
  top: 20%;
  left: 50%;
  width: 30%;
  height: 30%;
  transform: rotate(45deg) translate(-50%, 0);
  border: none;
  padding: 0;
  margin: 0 0 0 -4%;
  border-radius: 100% 0 0;
  cursor: pointer;
  background: transparent;
  outline: none;
`;

const PanControllerButtonBottom = styled.button`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 30%;
  height: 30%;
  transform: rotate(45deg) translate(-50%, 0);
  border: none;
  padding: 0;
  margin: 0 0 0 -4%;
  border-radius: 0 0 100% 0;
  cursor: pointer;
  background: transparent;
  outline: none;
`;

const PanControllerButtonLeft = styled.button`
  position: absolute;
    top: 45%;
    left: 4%;
      width: 30%;
  height: 30%;
  background: transparent;
  transform: rotate(45deg) translate(0, -50%);
  border: none;
  padding: 0;
  margin: 0 0 0 -4%;
  border-radius: 0 0 0 100%;
  cursor: pointer;
  outline: none;
`;

const PanControllerButtonRight = styled.button`
  position: absolute;
  top: 46%;
  right: 22%;
  width: 30%;
  height: 30%;
  background: transparent;
  transform: rotate(45deg) translate(0, -50%);
  border: none;
  padding: 0;
  margin: 0 0 0 -4%;
  border-radius: 0 100% 0 0;
  cursor: pointer;
  outline: none;
`;

const Scale = styled.button`
  position: absolute;
  left: 50%;
  ${e => e.bottom ? 'top: -50px' : 'top: -100px' };
  ${e => e.bottom ? 'padding-bottom: 5px' : '' };
  background: #000;
  color: #fff;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 20px;
  cursor: pointer;
  outline: none;
`;

const MapUI = ({ onZoom, onPanClick }) => {

  return (
    <PanController>
      <Scale onClick={e => onZoom(true)}>+</Scale>
      <Scale onClick={e => onZoom()} bottom={true}>-</Scale>
      <PanControllerImage src={panController} />
      <PanControllerButtonTop onClick={e => onPanClick('up')} />
      <PanControllerButtonBottom onClick={e => onPanClick('down')} />
      <PanControllerButtonLeft onClick={e => onPanClick('left')} />
      <PanControllerButtonRight onClick={e => onPanClick('right')} />
    </PanController>
  );
};


export default MapUI;
