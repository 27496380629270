import React, { Component } from 'react';
import styled from "styled-components";
import ReactPlayer from 'react-player';
import iconPlay from '../assets/play.svg'

const VideosList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 40px;
`;
const Video = styled.div`
  width: calc(50% - 10px);
  margin-bottom: 15px;
  box-shadow: 0px 2px 3px #00000029;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  overflow: hidden;
`;
const VideoPlayer = styled.div`
  position: relative;
  width: 100%;
  & video{
    box-shadow: 0px 2px 3px #00000029;
  }
`;
const VideoPlay = styled.img`
  position: relative;
  width: 36px;
  height: 36px;
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
  opacity: 0.8;
  &:hover{
    opacity: 1;
  }
`;

const VideoDescription = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 15px 10px;
`;
const VideoTitle = styled.div`
  color: #052572;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 6px;
`;
const VideoText = styled.div`
  color: #606060;
  font-size: 15px;
  font-weight: 400;
`;
const VideoPoster = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


class SheetVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      playing: [],
			language: null
    }

		this.ref = [];
		for(var i = 0; i < 25; i++){
			this.ref[i] = React.createRef();
		}
  }

	togglePlay(index){
		let playStatus = this.state.playing.slice(0);
		if(playStatus && playStatus[index]){
			playStatus[index] = !playStatus[index];
		}else{
			playStatus[index] = true;
		}
		this.setState({
			playing: playStatus
		});
	}

	componentDidMount() {

	}

	componentDidUpdate(prevProps) {
		if (this.props.videos !== prevProps.videos) {
			this.setState({
				playing: []
			});
  	}
	}

  static getDerivedStateFromProps(nextProps, prevState){
	 // console.log("getDerivedStateFromProps check");
     if(nextProps.videos!==prevState.videos || nextProps.language!==prevState.language){
	 		// console.log("getDerivedStateFromProps ok");
       return {
         videos: nextProps.videos,
         language: nextProps.language

       };
    }
    else return null;
  }

	render(){
		return (
			<VideosList>
				{this.state.videos.map((item, index) =>(
					<Video key={index}>
						<VideoPlayer>
							<div ref={this.ref[index]} onClick={() => this.togglePlay(index)}>
								<ReactPlayer controls={true} playing={this.state.playing && this.state.playing[index]} url={item.Url[this.state.language]} width="100%" height="100%"/>
								{!(this.state.playing && this.state.playing[index]) ? <>
									<VideoPoster src={item.Preview} />
									<VideoPlay src={iconPlay} />
								</> : null }
							</div>
						</VideoPlayer>
						<VideoDescription>
							<VideoTitle>{item.Title[this.state.language]}</VideoTitle>
							<VideoText dangerouslySetInnerHTML={{ __html: item.Description[this.state.language] }}></VideoText>
						</VideoDescription>
					</Video>
				))}
			</VideosList>
		);
	}
}


export default SheetVideo;
