import React, { Component } from 'react';
import styled from 'styled-components';

const LanguageSelector = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  right: 0px;
`;
const Language = styled.div`
  display: block;
  width: 40px;
  padding: 9px 0px;
  cursor: pointer;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  background: ${props => props.isActive ? "#ffffff" : "transparent"};
  color: ${props => props.isActive ? "#e16a54" : "#ffffff"};
`;


class OuterLanguageSelector extends Component {
  constructor(props) {
    super(props);
    this.setLanguage = 'it';//props.setLanguage;

    this.state = {
      language: 'it',//props.language,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState){
     if(nextProps.language!==prevState.language){
       return {
         language: nextProps.language
       };
    }
    else return null;
  }

  render() {
    return (<div/>);
    /*
    return (
      <LanguageSelector>
        <Language isActive={this.state.language === "it" ? 1 : 0} onClick={() => this.props.setLanguage("it")}>IT</Language>
        <Language isActive={this.state.language === "en" ? 1 : 0} onClick={() => this.props.setLanguage("en")}>EN</Language>
      </LanguageSelector>
    );
    */
  }
}

export default OuterLanguageSelector;
