import React from 'react';
import styled from 'styled-components';
import icon from '../../assets/calendar.svg';

import './style.scss';

const Wrapper = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink 1;
  height: 84px;
`;
const Content = styled.div`
  background-color: #000000;
  flex-basis: 52px;
  flex-grow: 1;
  flex-shrink: 1;
  line-height: 50px;
  padding: 0px 10px;
`;
const ContentNext = styled.div`
  background-color: #000000bf;
  flex-basis: 32pxq;
  flex-grow: 1;
  flex-shrink: 1;
  line-height: 30px;
  padding: 0px 10px;
`;


const Icon = styled.img`
  // margin-right: 20px;
  // position: relative;
  // top: 3px;
`;
const Time = styled.span`
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 19px;
  margin-right: 20px;
`;
const Title = styled.span`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 19px;
`;

const NextTime = styled.span`
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 11px;
  margin-right: 20px;
`;
const NextTitle = styled.span`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 11px;
`;




const openLightbox = (currentEvent, openStage, openAerostat) => {
  if(currentEvent !== null){
    if(currentEvent.area==="stage"){
      openStage();
    }else{
      openAerostat();
    }
  }
}

const Guide = ({currentEvent, nextEvent, language, openStage, openAerostat}) => {

  return (
    <div className={'Guide'} onClick={() => openLightbox(currentEvent, openStage, openAerostat)}>
      <button className={'Guide_Toggle'} ><img src={icon} alt={''} /></button>
      <div className={'Guide_Content'}>
          <div className={'Guide_ContentItem'}>
            <img className={'Guide_ContentItemIcon'} src={icon} alt={''} />
            {currentEvent ?
              <div className={'Guide_ContentItemInner'}>
                <div className={'Guide_ContentItemTime'}>{('0' + currentEvent.timestart.hour).slice(-2)}:{('0' + currentEvent.timestart.minute).slice(-2)}</div>
                <div className={'Guide_ContentItemTitle'}>{currentEvent.title[language]}</div>
              </div>
            : null }
          </div>
          <div className={'Guide_ContentItem Guide_ContentItem--next'}>
            {nextEvent ?
              <div className={'Guide_ContentItemInner'}>
                <div className={'Guide_ContentItemTime'}>{('0' + nextEvent.timestart.hour).slice(-2)}:{('0' + nextEvent.timestart.minute).slice(-2)}</div>
                <div className={'Guide_ContentItemTitle'}>{nextEvent.title[language]}</div>
              </div>
            : null }
          </div>
      </div>
    </div>
  );
};


export default Guide;